<template>
  <div>
    <!-- 搜索 -->
    <div class="searchBox">
      <kind-choose-view :show-school="true" :show-kc="false" :show-zy="false" :show-level="false" :type="null"
        :if-img="true" :entrants-type="3" @isOk="isOk" />
    </div>
    <div class="bigBox">
      <!-- 列表 -->
      <div class="courseList">
        <div v-for="item in list" :key="item.id" class="courstItem" :class="item.state == 1 ? 'liveIng' : ''"
          @click="goDetail(item)">
          <img class="itemImg" :src="item.lecturerUrl" />
          <div class="contentItem">
            <div class="timeItem">
              <i class="iconfont icon-daishouhuo" />
              <span>直播时间：{{ item.zbBeginTime }}</span>
            </div>
            <div class="titleItem twoEllipsis">{{ item.name }}</div>
            <div class="teacherItem">
              <div class="itemBox">讲师：{{ item.lecturerName }}</div>
              <div class="but">
                {{ item.stateb }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 分页 -->
      <el-pagination class="pagination colleges-pagination" :current-page="page.pageNum" :page-sizes="[12, 24, 36, 48]"
        :page-size="page.pageSize" layout="total, sizes, prev, pager, next, jumper" :total="page.total"
        @size-change="handleSizeChange" @current-change="handleCurrentChange" />
    </div>
  </div>
</template>

<script>
import { getSurfaceList } from "@/api/home";
import KindChooseView from "../../components/kindChooseViewsPicSeventh.vue";
import Bus from "@/api/bus";
import Vue from "vue";
import { getInfo } from "@/api/cookies";
import { Know } from "@/api/know.js";
const know = new Know();
export default {
  components: {
    KindChooseView,
  },
  data() {
    return {
      page: {
        pageSize: 12,
        pageNum: 1,
        total: 0,
      },
      list: [], // 课程列表
      currentItem: null, // 选中的公开课
      searchForm: {},
      userInfo: null,
      liveTodayList: [], // 今日直播列表
      zbStartTime: null,
    };
  },
  async created() {
    const date = new Date();
    this.zbStartTime = date.toLocaleDateString();
    this.liveToday();
    this.getList();
    this.userInfo = await getInfo();
  },
  methods: {
    mouseover() {
      const cover = document.getElementById("cover");
      cover.style.display = "flex";
    },
    mouseout() {
      const cover = document.getElementById("cover");
      cover.style.display = "none";
    },
    isOk(val) {
      this.searchForm.zyId = val.zyId;
      (this.searchForm.zState = val.statenName),
        (this.searchForm.kindId = val.kind);
      this.searchForm.schoolId = val.school;
      this.getList();
    },
    /* 公开课列表 */
    getList() {
      const data = {
        ...this.page,
        ...this.searchForm,
      };
      delete data.total;
      know.getNewPublicClassList(data).then((res) => {
        res.rows.filter((item, index) => {
          const month = new Date(item.zbBeginTime).getMonth() + 1;
          const date = new Date(item.zbBeginTime).getDate();

          const Hour = new Date(item.zbBeginTime).getHours();
          const Minutes = new Date(item.zbBeginTime).getMinutes();
          const nowMonth = new Date().getMonth() + 1;
          const nowDate = new Date().getDate();
          const nowHour = new Date().getHours();
          const nowMinutes = new Date().getMinutes();
          if (item.state == 2) {
            item.stateb = "查看回放";
          } else if (item.state == 1) {
            item.stateb = "进入直播";
          } else {
            item.stateb = "未开始";
          }
        });
        this.list = res.rows;
        this.page.total = res.total;
      });
    },
    /* 今日直播列表 */
    liveToday() {
      const data = {
        pageSize: 999,
        pageNum: 1,
        zbStartTime: this.zbStartTime,
        zbEndTime: this.zbStartTime,
      };
      know.getNewPublicClassList(data).then((res) => {
        if (res.rows.length > 0) {
          this.currentItem = res.rows[0];
        }

        this.liveTodayList = res.rows;
        this.liveTodayList.map((item, index) => {
          if (item.state == 1) {
            this.liveTodayList.unshift(this.liveTodayList.splice(index, 1)[0]);
          }
        });
      });
    },
    goDetail(item) {
      if (this.userInfo) {
        if (item.state == 2 && !item.playbackUrl) {
          this.$message({
            type: "error",
            message: "回放生成中",
          });
        } else {
          this.addRecord(item);
          const routeUrl = this.$router.resolve({
            path: "/publicClass/publicClassDetail",
            query: {
              imQunId: item.imQunId,
              id: item.id,
            },
          });
          window.open(routeUrl.href, "_blank");
        }
      } else {
        Bus.$emit("toLoginPage", true);
      }
      /* if (item.state == 2 && !item.playbackUrl) {
        this.$message({
          type: "error",
          message: "回放生成中",
        });
      } else {
        this.addRecord(item);
        let routeUrl = this.$router.resolve({
          path: "/publicClass/publicClassDetail",
          query: {
            id: item.id,
          },
        });
        window.open(routeUrl.href, "_blank");
      } */
    },
    /* 点击公开课添加观看记录 */
    addRecord(item) {
      const data = {
        userId: this.userInfo ? this.userInfo.id : null,
        userName: this.userInfo ? this.userInfo.userName : null,
        classId: item.id,
        port: this.userInfo ? this.userInfo.userSource : null,
      };
      know.addPublicityClassStudentRecord(data).then((res) => { });
    },
    /* 今日直播课程选择 */
    chooseCourse(item) {
      this.currentItem = JSON.parse(JSON.stringify(item));
    },

    /* 搜索 */
    selectPageList() {
      this.page = {
        pageSize: 12,
        pageNum: 1,
        total: 0,
      };
      this.getList();
    },
    /* 每页条数 */
    handleSizeChange(val) {
      this.page.pageSize = val;
      this.page.pageNum = 1;
      this.getList();
    },
    /* 当前页码 */
    handleCurrentChange(val) {
      this.page.pageNum = val;
      this.getList();
    },
  },
};
</script>

<style lang="less" scoped>
.bigBox {
  width: calc(1200px + 24px);
  height: auto;
  margin: 0px calc(50% - 612px);

  .courseList {
    display: flex;
    flex-wrap: wrap;

    /* 正在直播 */
    .liveIng {
      .timeItem {
        color: #4a6af0 !important;

        .iconfont {
          color: #4a6af0 !important;
        }
      }
    }

    .but {
      background: #ffffff !important;
      color: #4a6af0 !important;
    }

    .courstItem {
      cursor: pointer;
      width: calc(33% - 20px);
      height: 172px;
      background: #ffffff;
      border-radius: 8px 8px 8px 8px;
      opacity: 1;
      margin: 20px 12px 0px 12px;
      display: flex;
      padding: 24px;

      .itemImg {
        flex-shrink: 0;
        width: 60px;
        height: 60px;
        background: #c4c4c4;
        opacity: 1;
        border-radius: 50%;
        margin-right: 16px;
      }

      .contentItem {
        height: 100%;
        width: 100%;

        .timeItem {
          font-size: 12px;
          font-family: Microsoft YaHei-Regular, Microsoft YaHei;
          font-weight: 400;
          color: #666666;
          line-height: 12px;

          .iconfont {
            font-size: 12px;
            color: #666666;
            margin-right: 4px;
          }
        }

        .titleItem {
          height: 48px;
          font-size: 16px;
          font-family: Microsoft YaHei-Regular, Microsoft YaHei;
          font-weight: 400;
          color: #333333;
          line-height: 24px;
          margin-top: 12px;
        }

        .teacherItem {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-top: 12px;

          .itemBox {
            font-size: 12px;
            font-family: Microsoft YaHei-Regular, Microsoft YaHei;
            font-weight: 400;
            color: #999999;
            line-height: 12px;
          }

          .but {
            width: 92px;
            height: 32px;
            background: #ffffff;
            border-radius: 4px 4px 4px 4px;
            opacity: 1;
            border: 1px solid #4a6af0;
            font-size: 12px;
            font-family: Microsoft YaHei-Regular, Microsoft YaHei;
            font-weight: 400;
            color: #4a6af0;
            line-height: 30px;
            text-align: center;
          }
        }
      }
    }
  }

  .tag {
    height: 20px;
    border-radius: 3px;
    padding: 0 8px;
    line-height: 20px;
    text-align: center;
    color: #ffffff;
    font-size: 12px;
    margin-right: 8px;
  }

  .tagBack {
    background: linear-gradient(136deg, #727a8a 0%, #494e59 100%);
  }

  .tagLive {
    background: linear-gradient(138deg, #ff9a51 0%, #ff5e51 100%);
  }

  .tagNot {
    background: linear-gradient(136deg, #727a8a 0%, #555555 100%);
  }
}

.tui-img {
  margin: 0;
  width: 100%;
}
</style>
